import React from 'react';
import styled from 'styled-components';

import react from '../images/react.svg';
import gatsby from '../images/gatsby.svg';
import next from '../images/next.svg';
import vue from '../images/vue.svg';
import nuxt from '../images/nuxt.svg';
import angular from '../images/angular.svg';
import html from '../images/html.svg';

export const SupportsDiv = styled.div`
  margin: auto;
  width: 90%;
  margin-top: 50px;
  max-width: 1200px;

  h4 {
    text-align: center;
  }
  div {
    .textdiv {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 10px 1.2rem;
      max-width: 50px;
      img {
        width: 50px;
      }
      span {
        white-space: nowrap;
        font-size: 0.9rem;
        margin-top: 1rem;
      }
    }
  }

  .imgcont {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const Supports = ({ topText }) => {
  return (
    <SupportsDiv>
      <h4 className="center-block toptext text-3xl font-bold">{topText}:</h4>
      <div className="imgcont">
        <p className="textdiv">
          <img src={html} alt="logo" />
          <span>HTML</span>
        </p>
        <p className="textdiv">
          <img src={react} alt="logo" />
          <span>React</span>
        </p>
        <p className="textdiv">
          <img src={gatsby} alt="logo" />
          <span>Gatsby</span>
        </p>
        <p className="textdiv">
          <img src={next} alt="logo" />
          <span>Next Js</span>
        </p>
        <p className="textdiv">
          <img src={vue} alt="logo" />
          <span>Vue Js</span>
        </p>
        <p className="textdiv">
          <img src={nuxt} alt="logo" />
          <span>Nuxt Js</span>
        </p>
        <p className="textdiv">
          <img src={angular} alt="logo" />
          <span>Angular</span>
        </p>
      </div>
    </SupportsDiv>
  );
};
