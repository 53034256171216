import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframeNavForm';
import { Footer } from '../../../components/Footer/WindframeFooter';
import { Hero } from '../../../components/FormBuilder/Hero';
import { Cta } from '../../../components/FormBuilder/Cta';
// import { Preview } from '../../../components/FormBuilder/Preview';
import { Supports } from '../../../components/FormBuilder/Supports';
// import { Testimonial } from '../../../components/FormBuilder/Testimonial';

const Windframe = () => {
  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe Form builder - Visual form builder for rapidly building stunning forms and form
          layouts using tailwind css
        </title>
        <meta
          name="title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          name="description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase."
        />
        <meta
          property="og:title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          property="og:description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase. "
        />
        <meta
          property="twitter:title"
          content="Windframe Form builder -  Visual form builder for rapidly building stunning forms and form layouts using tailwind css"
        />
        <meta
          property="twitter:description"
          content="Easily create and edit different types of forms and form layouts using windframe. Create forms visually that you can easily copy to your codebase. "
        />
        <link rel="canonical" href="https://windframe.dev/form-builder/" />
        <meta property="og:url" content="https://windframe.dev/form-builder/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
        <meta
          name="twitter:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
              {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
              {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            {/* <Preview /> */}

            <Supports topText="Export your forms to" />
            {/* <Testimonial /> */}
            <Cta />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Windframe;
