import React from 'react';
import { Link } from 'gatsby';

import '../../styles/scss/style.scss';

export const Hero = () => {
  return (
    <div className="py-5" style={{ minHeight: 'calc(100vh - 85px)' }}>
      <div className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="hero__lead ">
                Visual form builder for rapidly building
                <br /> stunning forms and form layouts using tailwind css
              </h2>
              <p className="hero__text">
                Easily create and edit different types of forms and form layouts using windframe.
                Create forms visually that you can easily copy to your codebase. Get access to
                different templates you can easily use to get started quickly
              </p>
              <div className="hero__buttons mb-4">
                {process.env.NODE_ENV === 'production' && (
                  <Link
                    href="https://windframe.devwares.com/editor?form=true"
                    className="btn1 hero__btn btn__inverse"
                  >
                    <span className="btn__text">Build a Form</span>
                  </Link>
                )}
                {process.env.NODE_ENV === 'development' && (
                  <Link
                    href="http://localhost:3000/?form=true"
                    className="btn1 hero__btn btn__inverse"
                  >
                    <span className="btn__text">Build a Form</span>
                  </Link>
                )}
              </div>
            </div>
            <div className="hero__image">
              <video
                autoPlay
                muted
                loop
                className="feature__img"
                style={{ border: '2px solid #ddd', borderRadius: '1rem', paddingTop: '0.3rem' }}
              >
                <source src={require('../images/formheader.mp4')} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
