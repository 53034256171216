import React from 'react';
import { Link } from 'gatsby';
import '../../styles/scss/style.scss';

export const Cta = () => {

  return (    
      <div className="text-light py-5" style={{ background: 'rgba(26,32,44,1)' }}>
        <div className="container">
          <div className="hero mt-5">
            <p className="text-center mb-1">
              Save countless development hours by visually building and editing different projects using windframe
            </p>
            <h1 className="text-center mb-5 mx-auto" style={{ maxWidth: '800px', fontSize: "3rem" }}>
              Build forms, components and webpages more efficiently
            </h1>
            <div className="hero__buttons text-center mt-5">
              <Link href="https://windframe.dev/" className="btn1 hero__btn btn__inverse">
                <span className="btn__text">Learn more about windframe</span>
              </Link>
            </div>
            {/* <p className="text-center mt-2">View full <Link to="/windframe">documentation here</Link></p> */}
          </div>
        </div>
      </div>
  );
};

